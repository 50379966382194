export const SurveyEnum = Object.freeze({
  checkbox: 'checkbox',
  info: 'info',
  infoReverse: 'info-reverse',
  pic: 'pic',
  line: 'line',
  inline: 'inline',
  boolean: 'boolean',
  progress: 'progress',
  grid: 'grid',
  startLine: 'start-line',
})
