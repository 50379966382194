import React from 'react';
import {useTranslation} from "react-i18next";
import {LanguageEnum} from "../_business/LanguageEnum";

export default function Top({tref, type, className, children, ...props}) {
  const {i18n} = useTranslation();

  const handleChangeLang = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div ref={tref} className={'top type-' + type + (className ? ' ' + className : '')} {...props}>
      {
        process.env.REACT_APP_DEBUG &&
        <div style={{position: 'fixed', zIndex: 100, top: 5, left: 5}}>
          {Object.entries(LanguageEnum).map(([key]) => {
            if (key === i18n.resolvedLanguage)
              return true;

            return (
              <div key={key} onClick={() => handleChangeLang(key)} role={'button'}>{key}</div>
            );
          })}
        </div>
      }
      {children}
    </div>
  )
}
