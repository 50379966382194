import React from 'react';

export default function Checkbox({type = 'checkbox', label, checked = false, className, ...props}) {
  const [isChecked, setIsChecked] = React.useState(checked);

  React.useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  return (
    <div className={'custom-input ' + type}>
      <label>
        <input type={type} checked={isChecked}
          className={(className ? className + ' ' : '') + (isChecked ? 'checked' : '')}
          onChange={() => setIsChecked((prev) => !prev)}
          {...props}
        />
        {
          label &&
          <span>{label}</span>
        }
      </label>
    </div>
  )
}
