import React from 'react';

export default function Button({doRef, variant = 'primary', children, size, ...props}) {
  const newProps = {...props};

  newProps.className =
    'btn' + (size ? ' ' + size : '') +
    (variant ? ' btn-' + variant : '') +
    (props.className ? ' ' + props.className : '');

  return (
    <button ref={doRef} {...newProps}>
      {children}
    </button>
  )
}
