import React from 'react';
import {Link} from "react-router-dom";

export default function LinkButton({variant = 'primary', children, size, ...props}) {
  const newProps = {...props};

  newProps.className =
    'btn' + (size ? ' ' + size : '') +
    (variant ? ' btn-' + variant : '') +
    (props.className ? ' ' + props.className : '');

  return (
    <Link role={'button'} {...newProps}>
      {children}
    </Link>
  )
}
