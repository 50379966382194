import React from 'react';
import Stars from "../Stars";
import {Trans, useTranslation} from "react-i18next";


export default function Stores() {
  const {t} = useTranslation('common', {keyPrefix: 'paywall.info.stores'})

  return (
    <div className={'block items'} style={{padding: 20}}>
      <div className={'row gap-2 center'}>
        <img src={require('../../assets/img/paywall/wreath-left.png')} alt={''} width={44} height={68}/>
        <div className={'text-center'}>
          <h2 style={{marginBottom: 4}}>{t('header')}</h2>
          <p className={'small pre-line'}>{t('desc')}</p>
        </div>
        <img src={require('../../assets/img/paywall/wreath-right.png')} alt={''} width={44} height={68}/>
      </div>

      <div className={'block w-100 items'}>
        <div className={'row text-center'} style={{justifyContent: 'space-around', alignItems: 'flex-end'}}>
          <div>
            <Stars className={'mb-1'} size={16}/>
            <Trans i18nKey={'rating'} t={t}>
              <em className={'h3 marked'}>4,6</em> <small className={'notice'}>out of 5</small>
            </Trans>
          </div>
          <a rel="noreferrer" href={process.env.REACT_APP_IOS_LINK} target={'_blank'}>
            <img src={require('../../assets/img/paywall/apple-logo.svg').default} alt={''} width={28} height={28}
                 className={'d-block mx-auto mb-1'}/>
            <small className={'notice'}>{t('appStore')}</small>
          </a>
          <a rel="noreferrer" href={process.env.REACT_APP_GOOGLE_LINK} target={'_blank'}>
            <img src={require('../../assets/img/paywall/google-play-logo.svg').default} alt={''} width={28} height={28}
                 className={'d-block mx-auto mb-1'}/>
            <small className={'notice'}>{t('googlePlay')}</small>
          </a>
        </div>

        <div className={'row gap-2 small-sm'}>
          <div className={'panel row gap-2 center'}>
            <Trans i18nKey={'ratings'} t={t}>
              <strong>25K</strong><em className={'notice'}>5-star ratings</em>
            </Trans>
          </div>
          <div className={'panel row gap-2 center'}>
            <Trans i18nKey={'downloads'} t={t}>
              <strong>2M</strong><em className={'notice'}>downloads</em>
            </Trans>
          </div>
        </div>
      </div>
    </div>
  )
}
