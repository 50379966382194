import React from 'react';
import Top from "./parts/Top";
import Plan from "./parts/paywall/Plan";

import mixpanel from 'mixpanel-browser';
import {useParams} from "react-router-dom";
import Info from "./parts/paywall/Info";
import Payment from "./parts/paywall/Payment";
import {Trans, useTranslation} from "react-i18next";
import {TimerNew, TimerPanel} from "./Paywall";
import {LanguageEnum} from "./_business/LanguageEnum";
import RiveLoad from "./parts/RiveLoad";
import {scrollIntoViewWithOffset, setPrice} from "./utils/lib";
import Button from "./parts/Button";
import {useData} from "./utils/useData";

export default function Friday() {
  const
    {uid} = useParams(),
    {t, i18n} = useTranslation('common', {keyPrefix: 'friday'}),
    {t: t1} = useTranslation('common', {keyPrefix: 'paywall'})

  const
    now = new Date(),
    // targetDate = new Date(now.getFullYear(), 11, 1),
    targetDate = new Date(2024, 11, 1),
    diff = Math.floor((targetDate - now) / 1000),
    [timing] = React.useState(diff > 0 ? diff : 0)

  const
    // email = 'nick.lahoika@vocalimage.app',
    {data: {email}} = useData(`special-offers/info/${uid}`, {email: ''}),
    [active, setActive] = React.useState()

  React.useEffect(() => {
    window.localStorage.setItem('uid', uid)
    mixpanel.track_pageview({page: 'friday'})
  }, [])

  React.useEffect(() => {
    if (active)
      setPrice(active)
  }, [active])

  const holiday =
    {
      discount: 60,
      code: email ? `${email.split('@')[0].split('.')[0].substring(0, 8)}_bf_2024_60` : 'loading...',
      style: {
        background: `#aedc4a url(${require('../src/assets/img/paywall/black_friday.png')}) no-repeat`,
        backgroundSize: '100% 100%'
      }
    }

  return (
    <>
      <TimerPanel count={timing} keyPrefix={'friday'}/>

      <div className={'wrap'}>
        <Top className={'gap-3'}>
          {
            timing > 0
              ?
              <div className={'block items'}>
                <div className={'text-center'}>
                  <h1 className={'h2 pre-line mb-2'}>{t('h1')}</h1>
                  <span className={'tag tag-gold'}>{email}</span>
                </div>

                <RiveLoad width={350} height={238} src={`graphs_12_months.riv`} stateMachines="graph"
                          inputName={'localisation_id'} value={LanguageEnum[i18n.resolvedLanguage]}/>

                <div className={'block items'}>
                  <strong className={'pre-line'}>{t('pitch.title')}</strong>
                  <div className={'paragraph'}>
                    {
                      t1('pitch.list', {returnObjects: true}).map((item, i) =>
                        <p key={i} className={'notice small row'} style={{gap: '0.75rem'}}>
                          <img src={require('./assets/img/icon/star2.svg').default} alt={'★'}/>
                          {item}
                        </p>
                      )
                    }
                  </div>
                </div>

                <h3 className={'text-center pre-line'}>{t('plan.header')}</h3>

                <TimerNew timing={timing} holiday={holiday}/>

                <Plan path={'discount'} discount={true} onChange={item => setActive(item)}>
                  <div className={'row sm'}>
                    <em style={{marginBottom: '22px'}}>👍</em>
                    <div>
                      <p className={'small mb-1'}>{t('getMyPlan.desc')}</p>
                      <p className={'note fw-400'}>{t1('getMyPlan.note')}</p>
                    </div>
                  </div>
                  <Button onClick={() => scrollIntoViewWithOffset('#payment', 48)}>{t('getMyPlan.btn')}</Button>
                </Plan>
              </div>
              :
              <div className={'block items'}>
                <h1 className={'h2 pre-line text-center'}>{t('end')}</h1>
              </div>
          }

          <Info strict={true} guarantee={false}/>

          {
            timing > 0
              ?
              <Payment data={active} nextUrl={'/book'}>
                <Footer/>
              </Payment>
              :
              <Footer/>
          }
        </Top>
      </div>
    </>
  )
}

function Footer() {
  const {t} = useTranslation('common', {keyPrefix: 'friday'})

  return (
    <div className={'w-100 pre-line note md fw-400'}>
      <Trans i18nKey="footer" t={t}>
        <p className={'mb-2'}>You will need an iPhone or Android smartphone to use Vocal Image.</p>

        <p className={'notice mb-1'}>Secure checkout</p>
        <p className={'mb-2'}>All Information is encrypted and transmitted using Secure Sockets Layer protocol</p>

        <p className={'notice mb-1'}>Need help?</p>
        Contact us <a href={'mailto:support@vocalimage.app'} className={'link'}>here</a>
      </Trans>
    </div>
  )
}
