import React from 'react';
import Button from "./Button";
import {useTranslation} from "react-i18next";

export default function Back(props) {
  const {t} = useTranslation();

  return (
    <Button variant={'secondary'} size={'md'} className={'back'} {...props}>
      <img src={process.env.PUBLIC_URL + '/static/img/icon/back.svg'} alt="" width={16} height={16}/>
      {t('back')}
    </Button>
  )
}
