import React from 'react';
import Bottom from "./parts/Bottom";

import Top from "./parts/Top";

import Logo from "./parts/Logo";
import {getItem, getResults, getSurveyData} from "./utils/lib";

import {Steps} from "./Survey";
import {useTranslation} from "react-i18next";
import LinkButton from "./parts/LinkButton";
import {useNavigate, useParams} from "react-router-dom";
import Markdown from "react-markdown";
import RiveLoad from "./parts/RiveLoad";

export default function Summarize({step, toAdd, nextUrl, preload}) {

  const {uid} = useParams();

  const navigate = useNavigate();
  const {t, i18n} = useTranslation();
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);

  const [list, setList] = React.useState(
    getItem('summarize', [], window.sessionStorage)
  );
  const resultList = getItem('survey_results');

  // const sex =
  //   +resultList?.[
  //     surveyList.findIndex(({id}) => id === 'gender')
  //     ]?.[0] === 1 ? 'male' : 'female';

  const [state, setState] = React.useState({
    count: 1,
    typing: !!uid,
  });

  const commentRef = React.useRef();


  React.useEffect(() => {
    // const A24 = getExperiment('A24');

    if (
      !uid && (
        // A24.value === 'A' ||
        !list.length ||
        resultList.length < surveyList.length
      )
    ) {
      navigate(nextUrl, {replace: true});
      return;
    }

    fetch(
      process.env.REACT_APP_API_URI + 'web-sessions/coach',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          uid: (window.session_uid || window.sessionStorage.getItem('session_uid')),
        })
      }
    )
      .then(r => {});
      // .then(response => response.json())
      // .then(() => {});

    // setStep({...step, uid}, toAdd, true);

    if (uid && !list.length) {
      window.localStorage.setItem('uid', uid);
      window.sessionStorage.setItem('force', '1');

      try {
        console.log('try fetch summarize...');
        console.time('fetch');

        fetch(process.env.REACT_APP_API_URI + 'web-sessions/summarize', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            lang: i18n.resolvedLanguage,
            version: process.env.REACT_APP_VERSION,
            uid,
            session_uid: (window.session_uid || window.sessionStorage.getItem('session_uid')),
            age: window.localStorage.getItem('age'),
            data: getResults(surveyList, resultList),
          })
        })
          .then(response => {
            console.timeEnd('fetch');
            console.log(response);

            return response.json();
          })
          .then(({messages}) => {
            console.log('messages', messages)

            if (messages) {
              console.warn('valid');

              window.sessionStorage.setItem(
                'summarize',
                // JSON.stringify(state.summarize)
                JSON.stringify(messages)
              );

              setList(messages);
              typing();
            } else {
              console.warn('not valid');
              navigate(nextUrl);
            }
          });
      } catch (err) {
        console.error(err);
        navigate(nextUrl);
      }
    }
    // else
    //   typing();
  }, [])

  React.useEffect(() => {
    if (commentRef.current)
      commentRef.current.scrollIntoView({
        behavior: 'smooth'
      });
  }, [state.typing, commentRef.current])

  React.useEffect(() => {
    typing();
  }, [list])

  const typing = () => {
    if (list.length > state.count)
      setTimeout(() => {
        state.typing = true;
        setState({...state});

        addMessage();
      }, 2000);
  }

  const addMessage = () => {
    setTimeout(() => {
      state.count++;
      state.typing = false;
      setState({...state});

      typing();
    }, 7000);
  }

  return (
    <div className={'wrap fixed md'}>
      <Top>
        <Logo/>

        {
          !uid &&
          <Steps index={surveyList.length - 1}/>
        }

        <h1 className={'h3'}>{t('summarize.title')}</h1>

        <div className={'w-100 h-100 scrollable'}>

          {
            list.map((txt, ind) => {
              if (
                ind >= state.count && !state.typing ||
                ind > state.count && state.typing
              )
                return false;

              const isType = ind >= state.count && state.typing

              return (
                <div key={ind} ref={ind >= state.count && state.typing || ind >= state.count - 1 && !state.typing ? commentRef : null} className={'mb-2'}>
                  <Coach animation={isType}/>
                  {
                    isType
                      ?
                      <div className={'panel-comment notice md'}>
                        {t('summarize.typing')}
                        <span className="dots"><span>.</span><span>.</span><span>.</span></span>
                      </div>
                      :
                      <div className={'panel-comment fade-in'} style={{backgroundColor: '#0050FF', color: '#fff'}}>
                        <Markdown>{txt}</Markdown>
                      </div>
                  }
                </div>
              );
            })
          }
        </div>
      </Top>

      <Bottom preload={preload}>
        {
          // state.count >= list.length &&
          <LinkButton className={'fade-in'} to={nextUrl}>{t('continue')}</LinkButton>
        }
      </Bottom>
    </div>
  )
}

function Coach({animation}) {
  const {t} = useTranslation('common', {keyPrefix: 'summarize'})

  // const A31 = getExperiment('A31')

  return (
    <div className={'row sm mb-2'}>
      <div style={{width: 20, height: 20}}>
        <RiveLoad width={20} height={20} stateMachines="ai_coach" inputName={'animation'} value={animation}
                  src={`ai_coach.riv`} image={require(`./assets/img/rive/ai_coach.svg`).default}/>
      </div>
      <strong>{t('coach')}</strong>
    </div>
  );
}
