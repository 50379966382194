import React from 'react';
import Top from "./parts/Top";

import LinkButton from "./parts/LinkButton";
import {useNavigate} from "react-router-dom";
import {getExperiment, getItem, getSurveyData, setStep} from "./utils/lib";
import {Trans, useTranslation} from "react-i18next";
import Bottom from "./parts/Bottom";
import Button from "./parts/Button";
import mixpanel from 'mixpanel-browser';
import {BookEnum} from "./_business/BookEnum";
import {Slide, Slider} from "./parts/Slider";

export default function Book({step, nextUrl, preload}) {
  const
    {t, i18n} = useTranslation('common', {keyPrefix: 'book'}),
    {t: t0} = useTranslation(),
    navigate = useNavigate()

  const
    surveyList = getSurveyData(t0('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage),
    resultList = getItem('survey_results'),
    engNative =
      i18n.resolvedLanguage === 'en' &&
      +resultList?.[
        surveyList.findIndex(({id}) => id === 'native-speaker')
      ]?.[0] === 1,
    A41 = engNative ? {value: 'A'} : getExperiment('A41'),
    ultimateOnly = engNative || A41.value === 'A',

    price = getItem('price', null, window.sessionStorage),
    [book] = React.useState(ultimateOnly ? BookEnum.ultimate : BookEnum.bundle)

  React.useEffect(() => {
    if (!window.localStorage.getItem('uid')) {
      navigate('/')
      return
    } else if (!price) {
      navigate(nextUrl)
      return
    }

    // setStep({
    //   ...step,
    //   // product: window.localStorage.getItem('product') || 'noname'
    // })

    window.sessionStorage.setItem('success', '1')
  }, [])

  React.useEffect(() => {
    window.sessionStorage.setItem('book_type', book)
  }, [book])

  const handleClick = () => {
    if (window.guideAdded || window.sessionStorage.getItem('guide_added') === '1') {
      console.warn('book skip click')
      navigate(nextUrl)
      // mixpanel.track('book skip click', {
      //   product: window.localStorage.getItem('product') || 'noname'
      // });
      return
    }

    // mixpanel.track('book click', {
    //   // product: window.localStorage.getItem('product') || 'noname'
    // })
    setStep({...step, page: 'book click', type: book})

    window.guideAdded = true
    window.sessionStorage.setItem('guide_added', '1')

    fetch(
      `${process.env.REACT_APP_API_URI}web-payments/book`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: price.id,
          type: book,
          uid: window.localStorage.getItem('uid'),
          // transaction_id: window.localStorage.getItem('transaction_id')
        })
      }
    )
      .then(async r => {
        let data = {}

        if (r.ok) {
          try {
            data = await r.json()

            if (data.success) {
              mixpanel.people.set({book_purchase: true})
              setStep({
                ...step, page: 'book successful', type: book,
                ...data
              })
              window.sessionStorage.setItem('book_success', 1)
            }
          } catch (ex) {
            console.error(ex)
            data.error = 'Exception: ' + ex.message
          }
        } else
          data.error = 'Status: ' + r.status + ' ' + r.statusText

        console.log(data)

        // mixpanel.track('book checkout', {
        //   // product: window.localStorage.getItem('product') || 'noname',
        //   ok: r.ok,
        //   ...data
        // })
      })

    navigate(nextUrl)
  }

  if (!price)
    return

  // const A28 = i18n.resolvedLanguage === 'en' ? getExperiment('A28') : {value: 'A'};

  const renderBook = (key) => {
    return (
      <div className={'block items h-100 rounded-2'}
           style={{
             alignItems: 'center', padding: '19px 14px', gap: 15,
             background: 'linear-gradient(79.63deg, #2072F3 7.74%, #391A6B 92.26%)'
           }}
      >
        {
          !ultimateOnly &&
          <h2 className={'h3'}>{t(`${key}.h2`)}</h2>
        }
        <div className={'row center'}>
          <div className={'w-100'}>
            <img alt="" width={160} height={160} className={'mw-100 mx-auto'}
                 src={require(`./assets/i18/${['book1'].includes(key) ? i18n.resolvedLanguage : 'en'}/img/book/${key}.png`)}
            />
          </div>
          <div>
            <ul className={'small text-start pre-line mb-2'} style={{width: 146}}>
              <Trans i18nKey={`${key}.list`} t={t}>
                <li>20+ guided voice trainings</li>
                <li>Expert-level tips and advices</li>
                <li>Train for just 3 minutes per day</li>
              </Trans>
            </ul>

            <div className={'row sm'}>
              <Trans i18nKey={`${key}.tags`} t={t}>
                <span className={'tag tag-btn-sm'}>e-book</span>
                <span className={'tag tag-btn-sm'}>pdf</span>
              </Trans>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const ns = ultimateOnly ? 'one.' : ''

  return (
    <div className={'wrap fixed'}>
      <Top type={'center'} reload={true}>

        <h1 className={'h2 pre-line'}>{t(`${ns}h1`)}</h1>

        {
          ultimateOnly
            ?
            <>
              <p className={'notice small'}>{t('one.notice')}</p>
              <div className={'w-100'}>
                {renderBook(Object.keys(BookEnum).find(key => BookEnum[key] === book))}
              </div>
            </>
            :
            <Slider view={true}>
              {
                // onSlide={(index) => setBook(Object.entries(BookEnum)[index][1])}
                Object.entries(BookEnum).map(([key, value], i) =>
                  <Slide key={value} index={i}>
                    {
                      value === BookEnum.bundle
                        ?
                        <div className={'block h-100 rounded-2'}
                             style={{
                               padding: 16,
                               background: `#17153d url(${require(`./assets/img/book/bundle-back.png`)}) center`,
                               backgroundSize: 'cover'
                             }}
                        >
                          <div className={'w-100'}>
                            <img alt="" width={268} height={217} className={'mw-100 mx-auto'}
                                 style={{marginTop: -20, marginBottom: -40}}
                                 src={require(`./assets/i18/en/img/book/${key}.png`)}
                            />
                          </div>
                          <h2 className={'h3 pre-line'}>{t(`${key}.h2`)}</h2>
                        </div>
                        :
                        renderBook(key)
                    }
                  </Slide>
                )
              }
            </Slider>
        }

        <p className={'notice small mb-2'}>{t(`${ns}note`)}</p>

        <div className={'items w-100'}>
          <div className={'block-plan active'} style={{position: 'relative'}}>
            {
              price[book].promo &&
              <div className={'promo small'}><em>
                {price[book].promo[i18n.resolvedLanguage] || price[book].promo}
              </em></div>
            }
            <div className={'item'}>
              <div className={'w-100 text-start'}>
                <strong>
                  {price[book].name[i18n.resolvedLanguage] || price[book].name}
                </strong>
                <p>
                  <span className={'note lg'}>
                    {t('original', {
                      currencySymbol: price.currencySymbol,
                      fullPrice: price[book].full_price,
                    })}
                  </span>
                </p>
              </div>
              <div className={'nowrap'}>
                <sup>{price.currencySymbol}</sup>
                <big style={{fontSize: 40, fontWeight: 'bold'}}>{Math.trunc(price[book].price)}</big>
                <div>
                  <em>{Math.round(price[book].price % 1 * 100)}</em>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Button onClick={handleClick}>{t(`${ns}btn`)}</Button>
        <LinkButton variant={''} to={nextUrl}>{t('skip')}</LinkButton>
      </Top>

      <Bottom preload={preload}/>
    </div>
  )
}
