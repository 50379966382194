import {GoogleEventEnum} from "../_business/GoogleEventEnum";
import {sha256} from "js-sha256";
import Cookies from "js-cookie";


function gtagEvent(eIndex, params) {
  if (window.gtagConfig?.[eIndex])
    window.gtag(
      'event',
      'conversion', {
        'send_to': `${window.gtagConfig[0]}/${window.gtagConfig[eIndex]}`,
        ...{
          ...params,
          'transaction_id': window.session_uid,
        }
      }
    )
  else
    console.error('gtagConfig implementation');

  if (window.gtagConfigOur?.[eIndex])
    window.gtag(
      'event',
      'conversion', {
        'send_to': `${window.gtagConfigOur[0]}/${window.gtagConfigOur[eIndex]}`,
        ...{
          ...params,
          'transaction_id': window.session_uid,
        }
      }
    )
  else
    console.error('gtagConfigOur implementation');
}


export function trackContent(id) {
  if (window.ttq) {
    window.ttq.track('ViewContent', {
      contents: [
        {
          content_id: id // string. ID of the product. Example: "1077218".
        }
      ]
    }, {
      event_id: sha256('ViewContent_' + window.session_uid)
    });

    fetch(
      process.env.REACT_APP_API_URI + 'web-sessions/view',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          session_uid: window.session_uid,
          ttclid: Cookies.get('ttclid'),
          ttp: Cookies.get('_ttp'),
          url: window.location.pathname + window.location.hash,
          content_id: id,
        })
      }
    )
      .then(r => {});
  }
}

export function trackSignup(email) {
  gtagEvent(GoogleEventEnum.register);

  if (window.ttq) {
    window.ttq.identify({
      email: sha256(email), // string. The email of the customer if available. It must be hashed with SHA-256 on the client side.
      // phone_number: "<hashed_phone_number>", // string. The phone number of the customer if available. It must be hashed with SHA-256 on the client side.
      external_id: sha256(window.session_uid) // string. A unique ID from the advertiser such as user or external cookie IDs. It must be hashed with SHA256 on the client side.
    });

    // window.ttq.track('CompleteRegistration', {
    //   "contents": [
    //     {
    //       "content_id": "email" // string. ID of the product. Example: "1077218".
    //     }
    //   ]
    // }, {
    //   event_id: sha256('CompleteRegistration_' + window.session_uid)
    // });

    window.ttq.track('AddToCart', {
      contents: [
        {
          content_id: 'subscription' // string. ID of the product. Example: "1077218".
        }
      ]
    }, {
      event_id: sha256('AddToCart_' + window.session_uid)
    });
  }
}

export function trackPaywall() {
  gtagEvent(GoogleEventEnum.paywall);

  // if (window.ttq) {
  //   window.ttq.track('AddToCart', {
  //     "contents": [
  //       {
  //         "content_id": "subscription" // string. ID of the product. Example: "1077218".
  //       }
  //     ]
  //   }, {
  //     event_id: sha256('AddToCart_' + window.session_uid)
  //   });
  // }

  if (window.pintrk) {
    window.pintrk('track', 'signup', {
      event_id: sha256('Signup_' + window.session_uid),
    });
  }
}

export function trackCheckout({fullPrice, currency, id, name}) {
  gtagEvent(GoogleEventEnum.checkout);

  if (window.ttq) {
    window.ttq.track('InitiateCheckout', {
      value: fullPrice, // number. Value of the order or items sold. Example: 100.
      currency, // string. The 4217 currency code. Example: "USD".
      contents: [
        {
          content_id: id, // string. ID of the product. Example: "1077218".
          content_name: name // string. The name of the page or product. Example: "shirt".
        }
      ]
    }, {
      event_id: sha256('InitiateCheckout_' + window.session_uid)
    });
  }

  if (window.pintrk) {
    window.pintrk('track', 'addtocart', {
      event_id: sha256('InitiateCheckout_' + window.session_uid),
      value: fullPrice,
      order_quantity: 1,
      currency,
      order_id: window.session_uid,
      // promo_code: 'WINTER10',
      // property: 'Athleta',
      // search_query: 'boots',
      // video_title: 'How to style your Parker Boots',
      // lead_type: 'Newsletter',
      line_items: [
        {
          product_name: name,
          product_id: id,
          // product_category: 'Shoes',
          // product_variant_id: '1414-Red',
          // product_variant: 'Red',
          product_price: fullPrice,
          product_quantity: 1,
          // product_brand: 'Parker'
        }
      ]
    });
  }
}

export function trackPurchase({fullPrice, currency, id, name}) {
  gtagEvent(GoogleEventEnum.purchase, {'value': fullPrice, 'currency': currency});

  if (window.ttq) {
    window.ttq.track('CompletePayment', {
      value: fullPrice, // number. Value of the order or items sold. Example: 100.
      currency, // string. The 4217 currency code. Example: "USD".
      contents: [
        {
          content_id: id, // string. ID of the product. Example: "1077218".
          content_name: name // string. The name of the page or product. Example: "shirt".
        }
      ]
    }, {
      event_id: sha256('CompletePayment_' + window.session_uid)
    });
  }

  if (window.pintrk) {
    window.pintrk('track', 'checkout', {
      event_id: sha256('CompletePayment_' + window.session_uid),
      value: fullPrice,
      order_quantity: 1,
      currency,
      order_id: window.session_uid,
      // promo_code: 'WINTER10',
      // property: 'Athleta',
      // search_query: 'boots',
      // video_title: 'How to style your Parker Boots',
      // lead_type: 'Newsletter',
      line_items: [
        {
          product_name: name,
          product_id: id,
          // product_category: 'Shoes',
          // product_variant_id: '1414-Red',
          // product_variant: 'Red',
          product_price: fullPrice,
          product_quantity: 1,
          // product_brand: 'Parker'
        }
      ]
    });
  }
}

export function trackSuccess(transaction_id) {
  // if (window.pintrk) {
  //   window.pintrk('track', 'purchase', {
  //     ...window.pintrkOptions,
  //     event_id: sha256('PurchasePayment_' + window.session_uid),
  //   });
  // }
}
