import React from 'react';

export default function Community() {
  return (
    <div className={'items text-start'}>
      <div className={'item'}>
        <img src={process.env.PUBLIC_URL + '/static/img/icon/education.svg'} alt="" width={20} height={18} />
        <div>
          <p><strong>Unlimited Access</strong></p>
          <p className={'notice sm'}>Get all courses, trainings, and features</p>
        </div>
      </div>

      <div className={'item'}>
        <img src={process.env.PUBLIC_URL + '/static/img/icon/podcast.svg'} alt="" width={20} height={20} />
        <div>
          <p><strong>Voice Analyses</strong></p>
          <p className={'notice sm'}>Learn all parametrs of your voice</p>
        </div>
      </div>

      <div className={'item'}>
        <img src={process.env.PUBLIC_URL + '/static/img/icon/diary.svg'} alt="" width={20} height={12} />
        <div>
          <p><strong>Track the Progress</strong></p>
          <p className={'notice sm'}>See the changes with charts and charts</p>
        </div>
      </div>
    </div>
  )
}
