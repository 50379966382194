import React from 'react';
import {useTranslation} from "react-i18next";

export default function Faq() {
  const {t} = useTranslation('common', {keyPrefix: 'paywall.info.faq'});

  const list = t('list', {returnObjects: true});

  const [active, setActive] = React.useState();

  function handleSelect(index) {
    // e.target.classList.toggle('active');
    setActive(
      index === active ? -1 : index
    );
  }

  return (
    <div className={'block items'}>
      <h3 className={'h1 text-center'}>{t('title')}</h3>
      <div className={'items w-100'} style={{gap: '0.75rem'}}>
        {
          list.map((item, index) => {
            const actived = active === index;

            return (
              <div key={index} className={'item block-drop' + (actived ? ' active' : '')} onClick={() => handleSelect(index)}>
                <div className={'row'}>
                  {item.question}
                  <img src={process.env.PUBLIC_URL + '/static/img/icon/arrow-' + (actived ? 'up' : 'down') + '.svg'} alt={actived ? '↑' : '↓'} width={16} height={16} className={'right'} />
                </div>
                <p className={'notice md'}>{item.answer}</p>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
