import React from 'react';
import Top from "./parts/Top";
import Comments from "./parts/Comments";
import Bottom from "./parts/Bottom";
import Community from "./parts/Community";
import Button from "./parts/Button";
import {useNavigate} from "react-router-dom";

import mixpanel from 'mixpanel-browser';
import LinkButton from "./parts/LinkButton";

function Thanks() {
  const navigate = useNavigate();

  React.useEffect(() => {
    mixpanel.track_pageview({page: 'thanks'});
  }, []);

  return (
    <div className={'wrap fixed'}>
      <Top type={'center'}>
        <img src={process.env.PUBLIC_URL + '/static/img/logo.png'} alt="" width={40} height={40} />
        <h1>Thank you! 💛</h1>
        <div />
        <img src={process.env.PUBLIC_URL + '/static/img/thanks.png'} alt="" width={250} height={224} className={'mw-100'} />
        <div />

        <div className={'text-center'}>
          <p className={'mb-2'}>
            <a href={'/video'} className={'link row sm'} style={{justifyContent: 'center'}}>
              <img src={process.env.PUBLIC_URL + '/static/img/icon/play.svg'} alt="" width={14} height={16} />
              <strong>Watch the video</strong>
            </a>
          </p>
          <p>Learn more about how Vocal Image can help you speak with freedom and clarity.</p>
        </div>
      </Top>
      <Bottom footer={false}>
        <Button onClick={() => window.close() || navigate(-1)}>Close</Button>
      </Bottom>
    </div>
  );
}

function Cancel({onClose}) {
  const [great, setGreat] = React.useState(false);
  const [see, setSee] = React.useState(false);

  React.useEffect(() => {
    mixpanel.track_pageview({page: 'cancel'});
  }, []);

  if (great)
    return <Great />;

  if (see)
    return <See />;

  return (
    <div className={'wrap fixed md'}>
      <Top type={'center'}>
        <div className={'w-100 text-center'}>
          <Button variant={'link'} className={'back close'} aria-label={'Close'} onClick={onClose} style={{margin: 0, marginLeft: '-24px'}}>
            <img src={process.env.PUBLIC_URL + '/static/img/icon/close.svg'} alt="" width={16} height={16} />
          </Button>

          <img src={process.env.PUBLIC_URL + '/static/img/logo.png'} alt="" width={40} height={40} />
        </div>

        <img src={process.env.PUBLIC_URL + '/static/img/discount-offer.svg'} alt="" width={66} height={80} />
        <div />

        <h1 className={'text-center'}>
          <span className={'text-up'}>special offer</span><br />
          <em className={'gold'}>Now $1 for 3 month</em>
        </h1>
        <div />

        <div className={'block items'}>
          <p><strong>Vocal Image Premium: $1 for 3 months</strong></p>
          <p className={'notice'}>Yes, you read that right. Our best offet yet!</p>
          <p className={'notice'}>Keep access to Vocal Image’s personalized voice training sessions and expert content. Unleash the full potential of your voice!</p>
        </div>
      </Top>
      <Bottom footer={false}>
        <Button onClick={() => setGreat(true)}>Accept Special Offer</Button>
        <Button variant={''} onClick={() => setSee(true)}>Cancel Premium</Button>
      </Bottom>
    </div>
  );
}

function Great() {
  React.useEffect(() => {
    mixpanel.track_pageview({page: 'great'});
  }, []);

  return (
    <div className={'wrap fixed'}>
      <Top type={'center'}>
        <img src={process.env.PUBLIC_URL + '/static/img/logo.png'} alt="" width={40} height={40} />
        <h1>Great deal! 🎉</h1>
        <div />
        <img src={process.env.PUBLIC_URL + '/static/img/great-deal.png'} alt="" width={351} height={232} className={'mw-100'} />
        <div />

        <h2 className={'h3'}>Your special offer has been applied!</h2>
      </Top>
      <Bottom footer={false}>
        <LinkButton to={process.env.REACT_APP_WEB_LINK}>Close</LinkButton>
      </Bottom>
    </div>
  );
}

function See() {
  React.useEffect(() => {
    mixpanel.track_pageview({page: 'see'});
  }, []);

  return (
    <div className={'wrap fixed'}>
      <Top type={'center'}>
        <img src={process.env.PUBLIC_URL + '/static/img/logo.png'} alt="" width={40} height={40} />
        <img src={process.env.PUBLIC_URL + '/static/img/see-you-soon-text.svg'} alt="" width={236} height={40} className={'mw-100'} />
        <div />
        <img src={process.env.PUBLIC_URL + '/static/img/see-you-soon.png'} alt="" width={318} height={229} className={'mw-100'} />
        <div />

        <p className={'text-center'}>We’ve canceled your subscription, so you won’t be charged again.</p>
        <p />
        <p className={'text-center'}>Don’t worry, you’ll still be able to enjoy all premium features until your subscription ends on Jul 2, 2023.</p>
      </Top>
      <Bottom footer={false}>
        <LinkButton to={process.env.REACT_APP_WEB_LINK}>Close</LinkButton>
      </Bottom>
    </div>
  );
}

export default function Subscription() {
  const navigate = useNavigate();

  const [state, setState] = React.useState({});

  React.useEffect(() => {
    mixpanel.track_pageview({page: 'subscription'});
  }, []);

  function handleKeep() {
    state.keep = true;
    setState({...state});
  }

  function handleCancel() {
    state.cancel = true;
    setState({...state});
  }

  function handleCloseCancel() {
    state.cancel = false;
    setState({...state});
  }

  if (state.keep)
    return <Thanks />;

  if (state.cancel)
    return <Cancel onClose={handleCloseCancel} />;

  return (
    <div className={'wrap fixed md'}>
      <Top type={'center'}>
        <div className={'w-100 text-center'}>
          <Button variant={'link'} className={'back close'} aria-label={'Close'} onClick={() => navigate(-1)} style={{margin: 0, marginLeft: '-24px'}}>
            <img src={process.env.PUBLIC_URL + '/static/img/icon/close.svg'} alt="" width={16} height={16} />
          </Button>

          <img src={process.env.PUBLIC_URL + '/static/img/logo.png'} alt="" width={40} height={40} />
        </div>

        <h1 className={'h2 text-center'}>What you get with <em>Vocal&nbsp;Image</em></h1>

        <div />

        <Community />

        <div />

        <Comments />
      </Top>

      <Bottom footer={false}>
        <Button onClick={handleKeep}>Keep Premium</Button>
        <Button variant={''} onClick={handleCancel}>Cancel Premium</Button>
      </Bottom>
    </div>
  )
}
