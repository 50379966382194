import React from 'react';
import Bottom from "./parts/Bottom";
import Top from "./parts/Top";
import Button from "./parts/Button";
import {useNavigate} from "react-router-dom";
import Logo from "./parts/Logo";
import {SurveyEnum} from "./_business/SurveyEnum";
import {setStep} from "./utils/lib";
import {useTranslation} from "react-i18next";

export default function EmailOffers({step, toAdd, nextUrl, preload, locale}) {
  const {t} = useTranslation('common', {keyPrefix: 'emailOffers'});

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!window.localStorage.getItem('uid')) {
      navigate('/');
      return;
    }

    setStep(step, toAdd);
  }, []);

  const handleNext = (toEmail) => {
    if (toEmail)
      fetch(`${process.env.REACT_APP_API_URI}web-members/subscribe/${window.localStorage.getItem('uid')}`)

    navigate(nextUrl);
  };

  return (
    <div className={'wrap fixed lg'}>
      <Top type={SurveyEnum.info}>
        <Logo/>
        <h1 dangerouslySetInnerHTML={{__html: t('h1')}}/>
      </Top>

      <Bottom preload={preload} locale={locale}>
        <Button onClick={() => handleNext(true)}>{t('yes')}</Button>
        <Button variant={'secondary'} onClick={() => handleNext(false)}>{t('no')}</Button>
      </Bottom>
    </div>
  );
}
