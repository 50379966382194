// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --white-50: #707993;
  --white-80: #B3B9CD;
  --white-100: #eee;

  --primary-100: #6589EC;
  --primary-110: #95B1FF;
  --onboarding-primary-light: #6589EC;
  --secondary-green-light: #5DDEA7;
  --secondary-yellow: #FFD15A;
  --secondary-mint: #65E9FF;
  --warning-100: rgba(236, 125, 101, 1);

  --bg-30: #4E5466;
  --bg-50: #2B3242;
  --bg-60: #242A37;
  --bg-70: #1B202C;
  --bg-80: #161B26;
  --bg-90: #121620;
  --bg-100: #01030B;

  --radius: 0.5rem;
  --radius-sm: 0.25rem;
  --radius-md: 0.75rem;

  --max-width: 444px;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/Theme.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;;EAEjB,sBAAsB;EACtB,sBAAsB;EACtB,mCAAmC;EACnC,gCAAgC;EAChC,2BAA2B;EAC3B,yBAAyB;EACzB,qCAAqC;;EAErC,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;;EAEjB,gBAAgB;EAChB,oBAAoB;EACpB,oBAAoB;;EAEpB,kBAAkB;AACpB","sourcesContent":[":root {\n  --white-50: #707993;\n  --white-80: #B3B9CD;\n  --white-100: #eee;\n\n  --primary-100: #6589EC;\n  --primary-110: #95B1FF;\n  --onboarding-primary-light: #6589EC;\n  --secondary-green-light: #5DDEA7;\n  --secondary-yellow: #FFD15A;\n  --secondary-mint: #65E9FF;\n  --warning-100: rgba(236, 125, 101, 1);\n\n  --bg-30: #4E5466;\n  --bg-50: #2B3242;\n  --bg-60: #242A37;\n  --bg-70: #1B202C;\n  --bg-80: #161B26;\n  --bg-90: #121620;\n  --bg-100: #01030B;\n\n  --radius: 0.5rem;\n  --radius-sm: 0.25rem;\n  --radius-md: 0.75rem;\n\n  --max-width: 444px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
