import React from 'react';

export const useData = (url, value, body, load = true) => {
    const [state, setState] = React.useState({
        data: value,
        load
    })

    React.useEffect(() => {
        const dataFetch = async () => {
            setState({
                data: value,
                load: true
            })

            const data =
              await (
                await fetch(
                  process.env.REACT_APP_API_URI + url,
                  body ? {
                    method: 'POST',
                    body: JSON.stringify(body)
                  } : {}
                )
              ).json()

            setState({
                data, load: false
            })
        }

        dataFetch().then(() => {})
    }, [url])

    return {data: state.data, load: state.load, setState};
};
