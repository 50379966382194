import React from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import Bottom from "./parts/Bottom";
import Button from "./parts/Button";
import Top from "./parts/Top";
import Logo from "./parts/Logo";
import {setStep} from "./utils/lib";

import mixpanel from 'mixpanel-browser';
import { useTranslation } from 'react-i18next';

import {ReactComponent as IconArrow} from "./assets/img/icon/arrow.svg";
import RiveLoad from "./parts/RiveLoad";


const UtmEnum = Object.freeze({
  default: {
    ratio: 165 / 156,
    path: 'nice',
  },
  voicetype: {
    ratio: 165 / 196,
    path: 'sphere',
  },
  masculine: {
    ratio: 165 / 196,
    path: 'men',
  },
})


export default function Main({step, toAdd, nextUrl, preload}) {
  const {t, i18n} = useTranslation('common', {keyPrefix: 'main'});

  const ageList = ['18-24', '25-34', '35-44', '45+'];

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // const A5 = getExperiment('A5');
  const A5 = {value: 'A'};
  // const A7 = getExperiment('A7');
  // const A11 = i18n.resolvedLanguage === 'en' ? getExperiment('A11') : {value: 'A'};

  const utm = searchParams.get('utm_content');
  const id = `mapping.` + (utm || (window.landing.includes('masculinity') ? 'masculine' : ''));

  const utmText = t(i18n.exists('main.' + id) ? id : 'h1');
  const utmObj = UtmEnum[utm] || UtmEnum.default;

  React.useEffect(() => {
    if (utm) {
      console.log('utm', utm, utmText)
      mixpanel.register({
        utm_content: utm,
        utm_creative: searchParams.get('utm_creative')
      });
    }

    setStep(step, toAdd);

    window.sessionStorage.removeItem('success');
    window.localStorage.setItem('utm', utmText.replace(/<br>/g, ' '));
  }, []);

  function handleNext(age) {
    window.localStorage.setItem('age', age);
    mixpanel.people.set({age});
    navigate(nextUrl);
  }

  return (
    <>
    {
      // i18n.resolvedLanguage === 'en' &&
      // <TopApp/>
    }
    <div className={'wrap fixed'}>
      <Top type={'center'}>
        <Logo/>

        <h1 dangerouslySetInnerHTML={{__html: utmText}}/>

        <div className={'row center sm'}>
          <div className={'tag tag-btn-sm row w-auto'} style={{paddingTop: 4, paddingBottom: 4, gap: 4}}>
            <img width={17} height={16} src={require('./assets/img/icon/lights.svg').default} alt={'★'}/>{t('ai')}
          </div>
          <div className={'tag tag-btn-sm'}>{t('note')}</div>
        </div>

        {/*<p className={'notice md'}>Your voice changes over your lifetime multiple times.<br/>Let's find your starting point.</p>*/}

        <div className={'cards w-100'}>
          {
            ageList.map((item, ind) =>
              <div key={item} role={'button'} className={'card card-bg'} onClick={() => handleNext(item)}
                   style={A5.value === 'B' ? {
                     aspectRatio: utmObj.ratio,
                     backgroundImage: `url(${require(`./assets/img/age/${utmObj.path}/${item}.png`)})`
                   } : {}}
              >
                {
                  A5.value === 'A' &&
                  <RiveLoad width={149} height={100} src={`age_0${ind + 1}.riv`} stateMachines={`Age_0${ind + 1}`}
                            image={require(`./assets/img/age/${item}.png`)}
                    // image={<img src={require(`./assets/img/age/${item}.png`)} width={165} height={156} className={'w-100 mw-100'} alt={item}/>}
                  />
                }
                <Button size={'md'}>
                  {t('age', {item})}&nbsp;
                  <IconArrow width={20} height={20}/>
                </Button>
              </div>
            )
          }
        </div>
      </Top>

      <Bottom preload={preload}/>
    </div>
    </>
  )
}
