import React from 'react';
import {useTranslation} from "react-i18next";

export default function Paysafe({payment}) {
  const {t, i18n} = useTranslation();

  return (
    <p className={'row success-text'} style={{justifyContent: 'center', gap: '0.5rem'}}>
      <img src={require('../../assets/img/icon/secure.svg').default} alt="" width={14} height={16} />
      <em className={'small'}>{
        payment && i18n.exists('payment.secure')
          ? t('payment.secure')
          : t('paywall.plan.secure')
      }</em>
    </p>
  )
}
