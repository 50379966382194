import React from 'react';

// import Rive from '@rive-app/react-canvas';
// import RiveComponent from '@rive-app/react-canvas';
import {useRive, useStateMachineInput, Layout, Fit, Alignment} from "@rive-app/react-canvas";
import {useTranslation} from "react-i18next";

export default function RiveLoad({
  width, height, className = '', toLoad = true,
  src, stateMachines,
  inputName, value, image, locale = false
}) {
  // console.log('RiveLoad', className, src, stateMachines, inputName, value);

  const [load, setLoad] = React.useState(toLoad);

  const {i18n} = useTranslation();
  const params = {
    src: require(`../assets/${locale ? 'i18/' + i18n.resolvedLanguage + '/' : ''}rive/${src}`),
    autoplay: true,

    stateMachines,
    // artboard: 'Graph 2',
    // animations: 'my-animation',

    layout: new Layout({
      fit: Fit.Cover,
      alignment: Alignment.Center
    }),

    onStateChange: (e) => {
      console.log('onStateChange', e)

      if (e.type === 'statechange')
        // setLoad(false)
        setTimeout(() => setLoad(false), 25);
    },
  };

  const { rive, RiveComponent } = useRive(params);

  const m = useStateMachineInput(
    rive,
    stateMachines,
    inputName,
    value
  );
  // inputName && console.log('m', m);

  // const m = useStateMachineInput(
  //   rive,
  //   stateMachines,
  //   'ChooseGraph',
  //   2
  // );
  // console.log('m', m);

  // const v = useStateMachineInput(
  //   rive,
  //   stateMachines,
  //   // 'graph_02',
  //   // 'graph 2',
  //   'Version',
  //   2
  // );
  // console.log('v', v);

  // onclick
  // m?.fire()

  React.useEffect(() => {
    if (rive) {
      // setLoad(false);
      console.log(rive);

      // setTimeout(() => {
      //   console.log(m);
      //   m.fire();
      //   console.log('fired')
      // }, 3000)
    }
  }, [rive]);

  React.useEffect(() => {
    if (rive && value !== undefined && m)
      m.value = value;
  }, [value]);

  React.useLayoutEffect(() => {
    console.log(src, 'locale =', locale);
    rive && rive.load(params);

    // setTimeout(()=>{
      // console.log('!!!', riveRef.current);
    // }, 3000);

    // if (value && m?.value)
    //   m.fire();
    //   m.value = value;
  }, [src]);

  // return <RiveComponent src={require('../assets/rive/' + src)} className={className + (load ? ' load' : '')} {...props}/>;

  // return <Rive src={require('../assets/rive/' + src)} className={className + (load ? ' load' : '')} {...props}/>;

  // if (load && image)
  //   return image;

  const style = {aspectRatio: width / height};

  if (load && image)
    style.backgroundImage = `url(${image})`;

  return (
    <div className={`rive${className ? ' ' + className : ''}` + (load ? ` load${image ? '-image' : ''}` : '')} style={style}>
      <RiveComponent/>
    </div>
  );
}
