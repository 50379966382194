import React from 'react';
import Checkbox from "./Checkbox";
import {SurveyEnum} from "../_business/SurveyEnum";
import Button from "./Button";

function Choice({children, checked, onClick}) {
  return (
    <Button variant={'choice'} className={checked ? 'active' : ''} onClick={onClick}>
      {children}
    </Button>
  )
}

export default function ChoiceList({index, survey, checkedList, className, onChange}) {
  const [state, setState] = React.useState({
    checkedList: []
  });
  const listRef = React.useRef();

  React.useEffect(() => {
    if (listRef.current) {
      listRef.current.classList.add('animate-choice');

      setTimeout(() => {
        if (listRef.current)
          listRef.current.classList.remove('animate-choice');
      }, 500);
    }
  }, [(index >= 0 ? index : survey), listRef.current]);

  React.useEffect(() => {
    state.checkedList = checkedList ? [...checkedList] : [];
    setState({...state});
  }, [checkedList]);

  function handleSelect(item, index) {
    if (state.checkedList.includes(index)) {
      // if (survey.type && survey.type !== SurveyEnum.boolean) {
      if (survey.multiple) {
        const ind = state.checkedList.findIndex(i => i === index);
        state.checkedList.splice(ind, 1);
      }
    } else {
      if (survey.multiple)
        state.checkedList.push(index);
      else
        state.checkedList = [index];
    }

    onChange(state.checkedList);
    setState({...state});
  }

  const isChecked = (index) => {
    return state.checkedList.includes(index);
  }

  let cls = 'choice-list animate-choice ' + (survey.type === SurveyEnum.pic ? 'type-' + SurveyEnum.line + ' ' : '') + 'type-' + survey.type + (className ? ' ' + className : '');

  if (survey.multiple)
    cls += ' multiple';

  return (
    <div ref={listRef} className={cls}>
      {
        survey.list.map((item, index) => {
          return (
            <Choice key={index} checked={isChecked(index)} onClick={() => handleSelect(item, index)}>
              {
                item.icon &&
                <i>{item.icon}</i>
              }

              {
                item.image &&
                <div className={'thumb'}>
                  <img src={process.env.PUBLIC_URL + '/static/img/' + item.image} alt="" width={72} height={72} />
                  <Checkbox checked={isChecked(index)} />
                </div>
              }

              <em dangerouslySetInnerHTML={{__html: item.title}}/>

              {
                // item.note &&
                // <span className={'note lg'}>{item.note}</span>
              }

              {
                // [SurveyEnum.checkbox, SurveyEnum.line, SurveyEnum.checkboxInline].includes(survey.type)
                survey.multiple && !item.image &&
                <Checkbox checked={isChecked(index)}/>
              }
            </Choice>
          )
        })
      }
    </div>
  )
}
