import React from 'react';
import {useTranslation} from "react-i18next";
// import Rive from "@rive-app/react-canvas";

export default function RivePreload({src, locale = false}) {

  const {i18n} = useTranslation();

  // return (
  //   <div style={{position: 'absolute', left: -5000, top: -5000, width: 412, height: 232}}>
  //     <Rive {...props}/>
  //   </div>
  // )

  return <link rel="prefetch" href={require(`../assets/${locale ? 'i18/' + i18n.resolvedLanguage + '/' : ''}rive/${src}`)} as="fetch" crossOrigin="anonymous"/>;
}
