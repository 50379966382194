import React from 'react';

export default function Winners() {
  return (
    // <div className={'achives'}>
    //   <div className={'d-inline-block'}>
    //     <div className={'items'}>
    //       {
    //         ['TechChill 2022', 'AWS AI challenge', 'Latitude59', 'New Nordic Leads 4'].map((item, index) => {
    //           return (
    //             <div key={index} className={'item'}>
    //               <div className={'tag'}>{item}</div>
    //               <img src={process.env.PUBLIC_URL + '/static/img/icon/win.png'} alt="" width={58} height={36} />
    //             </div>
    //           )
    //         })
    //       }
    //     </div>
    //   </div>
    // </div>
    <div className={'cards'}>
      {
        ['AWS AI<br>Сhallenge', 'Startup Fair<br>2023', 'TechChill<br>2022', 'Latitude59<br>2022'].map((item) =>
          <div key={item} className={'item'}>
            <img src={require('../assets/img/icon/win.png')} alt="" width={54} height={32} />
            <div className={'text-start'}><strong className={'small'} dangerouslySetInnerHTML={{__html: item}}></strong></div>
          </div>
        )
      }
    </div>
  )
}
