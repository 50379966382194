import React from 'react';
import {Link} from "react-router-dom";

export default function Logo({path = ''}) {
  return (
    <Link to="/">
      <img className={'d-block'} src={require(`../assets/img${path}/logo.png`)} width={108} height={24} alt={'logo'}/>
    </Link>
  )
}
