import React from 'react';
import Back from "./parts/Back";
import Top from "./parts/Top";
import Button from "./parts/Button";
import {useNavigate} from "react-router-dom";
import LinkButton from "./parts/LinkButton";

export default function Account() {
  const navigate = useNavigate();

  function handleBack() {
    window.close() || navigate(-1);
  }

  return (
    <div className={'wrap'}>
      <Top>
        <Back onClick={handleBack} />

        <h1>Your Account</h1>
        <p className={'notice'}>Access and manage your Vocal Image account, including your email and subscription settings.</p>

        <div className={'items'}>
          <div className={'block sm'}>
            <div className={'row mb-1'}>
              <label className={'w-100'}>Email</label>
              <Button variant={'secondary'} size={'sm'}>Edit</Button>
            </div>
            <p className={'notice'}>emailnamebigbigbigbigbigbig@gmail.com</p>
          </div>

          <div className={'block sm'}>
            <div className={'row mb-1'}>
              <label className={'w-100'}>Subscription</label>
              <LinkButton variant={'secondary'} size={'sm'} to={'/subscription'}>Manage</LinkButton>
            </div>
            <p className={'notice'}>
              Free Trial<br />
              3 Month subscription begin Jul 2, 2023
            </p>
          </div>
        </div>
      </Top>
    </div>
  )
}
