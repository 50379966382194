import React from 'react';
import Top from "./parts/Top";
import Plan from "./parts/paywall/Plan";
import mixpanel from 'mixpanel-browser';
import {useNavigate} from "react-router-dom";
import Info from "./parts/paywall/Info";
import Payment from "./parts/paywall/Payment";
import {getItem, getSurveyData, scrollIntoViewWithOffset, setPrice, setStep} from "./utils/lib";
import RiveLoad from "./parts/RiveLoad";
import {Trans, useTranslation} from "react-i18next";
import {LanguageEnum} from "./_business/LanguageEnum";
import {trackPaywall} from "./utils/tracking";
import Button from "./parts/Button";


function Timer({id, time, className, minutes, seconds}) {
  const {t} = useTranslation('common')

  const
    d = Math.floor(time / (24 * 60 * 60)),
    h = Math.floor((time % (24 * 60 * 60)) / (60 * 60)),
    m = Math.floor((time % (60 * 60)) / 60),
    s = time % 60,
    // hours = (h < 10 ? '0' : '') + h,
    hours = (Math.floor(time / (60 * 60)) < 10 ? '0' : '') + Math.floor(time / (60 * 60)),
    min = (m % 60 < 10 ? '0' : '') + (m % 60),
    // min = (Math.floor(time / 60) < 10 ? '0' : '') + Math.floor(time / 60),
    sec = (s < 10 ? '0' : '') + s

  if (minutes)
    return <b className={className} id={id}>{h > 0 ? hours : min}</b>
  else if (seconds)
    return <b className={className} id={id}>{h > 0 ? min : sec}</b>

  let dateStr

  if (h > 0) {
    dateStr = t('date', {d, h, m}).split('{{s}}')[0].trim()

    if (d <= 0) {
      const arr = dateStr.split(' ')
      arr.splice(0, 1)
      dateStr = arr.join(' ')
    }
  } else
    dateStr = `${min}:${sec}`

  return (
    <strong className={className} id={id}>{dateStr}</strong>
  )
}


export function TimerPanel({count, onTime, keyPrefix = 'paywall'}) {
  const {t} = useTranslation('common', {keyPrefix})

  let timer;
  const [time, setTime] = React.useState(count)

  React.useEffect(() => {
    timer = setInterval(() => {
      setTime(time => {
        onTime && onTime(time - 1)

        if (time <= 0) {
          mixpanel.track('time is out')
          // onClose && onClose()

          // document.querySelectorAll('.reserved').forEach((item) => {
          //   item.style.display = 'none'
          // })
          // document.querySelector('.top').removeAttribute('style')

          clearInterval(timer)

          return time
        }

        return time - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  React.useEffect(() => {
    if (document.getElementById('time')) {
      document.querySelectorAll('.timing').forEach(function (el) {
        el.innerHTML = document.getElementById('time').innerHTML
      })

      let time = document.getElementById('time').innerHTML.split(':')

      if (time.length <= 1) {
        const arr = time[0].split('&nbsp;')

        if (arr.length > 2)
          time = [
            parseInt(arr[0]) * 24 + parseInt(arr[1]),
            parseInt(arr[2])
          ]
        else
          time = [
            parseInt(arr[0]),
            parseInt(arr[1])
          ]

        if (time[0] < 10)
          time[0] = '0' + time[0]

        if (time[1] < 10)
          time[1] = '0' + time[1]
      }

      document.querySelectorAll('.timing-min').forEach(function (el) {
        el.innerHTML = time[0]
      })
      document.querySelectorAll('.timing-sec').forEach(function (el) {
        el.innerHTML = time[1]
      })
    }
  }, [time])

  React.useEffect(() => {
    setTime(count)
  }, [count])

  // if (time <= 0)
  //   return true

  return (
    <div className={'panel-timer'}>
      <div className={'wrap px-2 text-center'}>
        <p>
          <Trans i18nKey="expires" t={t}>
            <em>Intro offer expires in:</em> <Timer id={'time'} time={time}/>
          </Trans>
        </p>
      </div>
    </div>
  )
}


export default function Paywall({step, toAdd, nextUrl}) {
  const
    {t} = useTranslation('common', {keyPrefix: 'paywall'}),
    {t: t0, i18n} = useTranslation(),
    navigate = useNavigate()

  const
    surveyList = getSurveyData(t0('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage),
    resultList = getItem('survey_results'),
    goalList = t('goals', {returnObjects: true}),
    [active, setActive] = React.useState(),
    [timing] = React.useState(15 * 60)

  const sex =
    +resultList?.[
      surveyList.findIndex(({id}) => id === 'gender')
    ]?.[0] === 0 ? 'boy' : 'girl'

  // const A5 = getExperiment('A5')
  const A5 = {value: 'A'}

  // const experiment = getExperiment('A1')
  //
  // if (['C', 'D'].includes(experiment.value)) {
  //   goalList.splice(-1)
  //   goalList.splice(5, 1)
  // }

  // const A23 = i18n.resolvedLanguage === 'en' ? getExperiment('A23') : {value: 'A'};
  // const A27 = i18n.resolvedLanguage === 'en' ? getExperiment('A27') : {value: 'A'};
  // const A30 = getExperiment('A30');

  const
    s1 = surveyList.findIndex(({id}) => id === 'target1'),
    g1 =
      window.landing.includes('masculinity')
        ? surveyList[s1].list.findIndex(({id, title}) => id === 'Masculinity' || title === 'Masculinity')
        : +resultList?.[s1]?.[0] || 0

  const goal = goalList?.[g1] || goalList[0]

  if (!goal.title) {
    try {
      // const
      //   s2 = surveyList.findIndex(({id}) => id === 'target2'),
      //   g2 = +resultList?.[s2]?.[0]

      goal.title = surveyList[s1].list[g1]?.title || surveyList[s1].list[0]?.title; // + ' and ' + surveyList[s2].switch[g1].list[g2].title.toLowerCase();
    } catch(e) {
      console.error(e)
    }
  }

  const
    goalBoy = goal['boy'],
    goalSex = goal[sex] || goalBoy

  const advantagesList =
    i18n.exists(`info.advantages.list-${g1}`)
      ? t(`info.advantages.list-${g1}`, {returnObjects: true})
      : null

  React.useEffect(() => {
    if (!window.localStorage.getItem('uid')) {
      navigate('/')
      return
    }

    // window.backTimer = setInterval(() => {
      if (window.sessionStorage.getItem('success')) {
        window.sessionStorage.removeItem('success')
        navigate('/', {replace: true})
        return
      }
    // }, 200);

    setStep(step, toAdd)
    // setExperiment(A4)

    trackPaywall()

    // return () => {
    //   clearInterval(window.backTimer)
    // }
  }, [])

  React.useEffect(() => {
    if (active)
      setPrice(active)
  }, [active])

  //onTime={(time) => setTiming(time)}
  return (
    <>
      <TimerPanel count={timing}/>

      <div className={'wrap'}>
        <Top className={'gap-3'}>
          <div className={'block items'}>
            <h1 className={'h2 text-center pre-line'}>{t('h1')}</h1>

            <div className={'goal'}>
              <div className={'subtitle' + (goal.title.includes('<br>') ? ' compact' : '')} dangerouslySetInnerHTML={{__html: goal.title}}/>
              <div className={'row gap-0 stretch'}>
                <div className={'sad'}>
                  <b className={'tag-note notice xs mt-1'}>{t('now')}</b>
                  <img src={require(`./assets/img/paywall/goal/${A5.value === 'B' ? `${goal.id}/sad-${sex}.png` : `sad-${sex}.svg`}`)} width={139} height={120} alt={''} className={'rounded-2 w-100 aspect-2'}/>
                  <strong className={'small'} dangerouslySetInnerHTML={{__html: goalSex.sad || goalBoy.sad}}/>
                </div>
                <div className={'happy'}>
                  <b className={'tag-note notice xs mt-1'} style={{color: 'var(--bg-100)', backgroundColor: 'var(--white-100)'}}>{t('in3Months')}</b>
                  <img src={require(`./assets/img/paywall/goal/${A5.value === 'B' ? `${goal.id}/happy-${sex}.png` : `happy-${sex}.svg`}`)} width={139} height={120} alt={''} className={'rounded-2 w-100 aspect-2'}/>
                  <strong className={'small'} dangerouslySetInnerHTML={{__html: goalSex.happy || goalBoy.happy}}/>
                </div>
              </div>
              <div className={'info'}>
                <p className={'notice xs'}>{t('note')}</p>
                <div className={'row'}>
                  <p className={'notice xs'} dangerouslySetInnerHTML={{__html: t('joined1')}}/>
                  <p className={'notice xs'} dangerouslySetInnerHTML={{__html: t('joined2')}}/>
                </div>

                {
                  timing > 0 &&
                  <>
                    <strong className={'reserved'}>{t('reserved.title')}</strong>
                    <p className={'reserved small-sm'} dangerouslySetInnerHTML={{__html: t('reserved.time')}}/>
                    <p className={'reserved note'} style={{color: '#fff'}}>
                      <Trans i18nKey="reserved.remaining" t={t}>
                        TIME REMAINING: <Timer time={timing} className={'timing gold'}/>
                      </Trans>
                    </p>
                  </>
                }
              </div>
            </div>

            <RiveLoad width={350} height={238} locale={['de'].includes(i18n.resolvedLanguage)} src={`graphs.riv`} stateMachines="graph" inputName={'localisation_id'} value={LanguageEnum[i18n.resolvedLanguage]}/>

            <div className={'block items'}>
              <strong className={'pre-line'}>{t('pitch.title')}</strong>
              <div className={'paragraph'}>
                {
                  t('pitch.list', {returnObjects: true}).map((item, i) =>
                    <p key={i} className={'notice small row'} style={{gap: '0.75rem'}}>
                      <img src={require('./assets/img/icon/star2.svg').default} alt={'★'}/>
                      {item}
                    </p>
                  )
                }
              </div>
            </div>

            <h3 className={'text-center pre-line'}>{t('plan.header')}</h3>
            <TimerNew g1={g1} timing={timing}/>
            <Plan onChange={item => setActive(item)}>
              <div className={'row sm'}>
                <em style={{marginBottom: '22px'}}>👍</em>
                <div>
                  <p className={'small mb-1'}>{t('getMyPlan.desc')}</p>
                  <p className={'note fw-400'}>{t('getMyPlan.note')}</p>
                </div>
              </div>
              <Button onClick={() => scrollIntoViewWithOffset('#payment', 48)}>{t('getMyPlan.btn')}</Button>
            </Plan>
          </div>

          <Info advantagesList={advantagesList}/>
          <Payment data={active} nextUrl={nextUrl}/>
        </Top>
      </div>
    </>
  )
}


export function TimerNew({g1, timing, holiday}) {
  const {t} = useTranslation('common', {keyPrefix: 'paywall.plan.offerEndsNew'})

  const
    date = new Date(),
    dateCode =
      date.toLocaleDateString('en', {month: "short", year: "numeric"})
        .replace(' 20', '').toLowerCase()

  const codeList = [
    "commskills",
    "relationship",
    "career",
    "singing",
    "masculine",
    "confidence",
    "public",
    "sexyvoice",
    "leadership"
  ]

  const holidayList = [
    // {
    //   start: '2024-11-29',    // >= yyyy-mm-dd
    //   end: '2024-12-02',      // < yyyy-mm-dd
    //   code: 'blackfriday',    // or list by goal
    // },
    // {
    //   start: '2024-12-02',    // 1 day
    //   // end: '2024-12-03',
    //   // days: 1,
    //   code: 'cybermonday',
    // },
    // {
    //   start: '01-01',         // >= mm-dd every year
    //   // end: '01-08',        // < mm-dd every year
    //   days: 7,                // default 1 day
    //   code: 'newyear',
    // }
  ]

  // const A36 = getExperiment('A36')
  //
  // if (A36.value === 'B')
  //   holidayList.push({
  //     start: '2024-10-22',    // >= yyyy-mm-dd
  //     end: '2024-11-07',      // < yyyy-mm-dd
  //     code: 'halloween24',
  //     style: {
  //       background: `#ff8c00 url(${require('../src/assets/img/paywall/halloween.png')}) no-repeat`,
  //       backgroundSize: '100% 100%'
  //     }
  //   })

  let code = (codeList[g1] || codeList[0]) + '_' + dateCode

  for (const h of holidayList) {
    const
      startDate = new Date(Date.parse((h.start.split('-').length < 3 ? date.getFullYear() + '-' : '') + h.start)),
      endDate = new Date(
        h.end
          ? Date.parse((h.end.split('-').length < 3 ? date.getFullYear() + '-' : '') + h.end)
          : startDate.getTime() + (h.days || 1) * 24 * 60 * 60 * 1000
      )

    if (
      date.getTime() >= startDate.getTime() &&
      date.getTime() < endDate.getTime()
    ) {
      // code = h.code
      holiday = h
      break
    }
  }

  if (holiday)
    code = holiday.code

  return (
    <div className={'block-plan new-timer'} style={holiday?.style}>
      <div className={'row md wide'}>
        <div className={'items'}>
          <strong>{t('title1')}</strong>
          <div className={'items facing gap-0'}
               style={{padding: '20px 12px 12px 12px', position: 'relative'}}
          >
            <div className="promo small">
              <em>
                <Trans i18nKey="discount" t={t} values={{value: 33, promo: holiday?.discount || 50}}>
                  <small>33%</small> <strong>50% off</strong>
                </Trans>
              </em>
            </div>
            <s className={'note md fw-400'}>newcomer_{dateCode}</s>
            <b>{code}</b>
          </div>
        </div>
        <div className={'items'}>
          <strong className={'pre-line'}>{t('title2')}</strong>
          <div className={'row xs clock'}>
            <div className={'items facing gap-2'}>
              <Timer minutes={true} time={timing} className={'timing-min'}/>
              <small className={'note'}>{t(timing / 60 >= 60 ? 'hours' : 'minutes')}</small>
            </div>
            <div className={'items facing gap-2'}>
              <Timer seconds={true} time={timing} className={'timing-sec'}/>
              <small className={'note'}>{t(timing / 60 >= 60 ? 'minutes' : 'seconds')}</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
