import React from 'react';
import Back from "./parts/Back";
import Bottom from "./parts/Bottom";
import Top from "./parts/Top";
import Button from "./parts/Button";
import ChoiceList from "./parts/ChoiceList";
import {SurveyEnum} from "./_business/SurveyEnum";
import {useNavigate} from "react-router-dom";
import mixpanel from 'mixpanel-browser';

import {getItem, getSurveyData, setStep, upTimer} from "./utils/lib";

import {ReactComponent as IconNo} from "./assets/img/icon/no.svg";
import {ReactComponent as IconYes} from "./assets/img/icon/yes.svg";
import Logo from "./parts/Logo";

import RiveLoad from "./parts/RiveLoad";
import RivePreload from "./parts/RivePreload";
import {useTranslation} from "react-i18next";
import {trackContent} from "./utils/tracking";
// import {useGeo} from "./utils/useGeo";


const MonthEnum = Object.freeze({
  ru: [
    'январю',
    'февралю',
    'марту',
    'апрелю',
    'маю',
    'июню',
    'июлю',
    'августу',
    'сентябрю',
    'октябрю',
    'ноябрю',
    'декабрю',
  ],
  // uk: [
  //   'січню',
  //   'лютому',
  //   'березню',
  //   'квітню',
  //   'травню',
  //   'червню',
  //   'липню',
  //   'серпню',
  //   'вересню',
  //   'жовтню',
  //   'листопаду',
  //   'грудню',
  // ]
});

// deprecated
// export function TopApp() {
//   const A3 = getExperiment('A3');
//   const {country, setCountryCode} = useGeo();
//
//   React.useEffect(() => {
//     window.updateCountry = (code) => {
//       console.log('updateCountry', code)
//       setCountryCode(code);
//     };
//
//     return () => {
//       delete window.updateCountry;
//     };
//   }, []);
//
//   React.useEffect(() => {
//     if (country && !window.experimentIsSet) {
//       window.experimentIsSet = true;
//       setExperiment(A3);
//     }
//   }, [country]);
//
//   if (!country || A3.value === 'B')
//     return;
//
//   return (
//     <div className={'top-up row sm center'} style={{
//       backgroundColor: '#274C46', textAlign: 'center', fontWeight: 700, padding: '0.25rem 1.25rem'
//     }}>
//       <em className={'small-sm'}>TOP APP IN {country.toUpperCase()}</em>
//       <em className={'gold small row xs center w-auto'}>
//         <img src={require('./assets/img/icon/star.svg').default} alt="★" width={12} height={12}/>
//         4.8
//       </em>
//     </div>
//   );
// }


export function Steps({index}) {
  const {t, i18n} = useTranslation();
  const steps = t('steps', {ns: 'survey', returnObjects: true});

  if (i18n.resolvedLanguage === 'en') {
    // const A13 = getExperiment('A13');
    //
    // if (A13.value === 'B') {
    //   steps[2].count = 11;
    // }

    // const A14 = getExperiment('A14');
    //
    // if (A14.value === 'B') {
    //   steps[1].count = 13;
    // }
  }

  // const A15 = getExperiment('A15');
  //
  // if (A15.value === 'B') {
  //   steps[0].count = 8;
  //   steps[2].count = 8;
  // }

  let step, stepCount = 0, count = 0;

  for (const item of steps)
    count += item.count;

  if (index >= count)
    return;

  return (
    <>
      <div className={'row xs'}>
        <i className={'step-circle active'}/>
        {
          steps.map((item, i) => {
            stepCount += item.count;

            let
              active = ' active',
              percent = 100;

            if (!step) {
              if (index < stepCount) {
                step = item;

                if (index + 1 < stepCount) {
                  active = ' sub-active';
                  percent = 100 - Math.round((stepCount - index - 1) / (item.count / 100));
                }
              }
            } else if (step) {
              active = '';
              percent = 0;
            }

            return (
              <React.Fragment key={i}>
                <div className={'progress sm animate'}>
                  <div style={{width: percent + '%'}}></div>
                </div>
                <i className={'step-circle' + active}/>
              </React.Fragment>
            );
          })
        }
      </div>

      <div className={'small marked'}>{step?.title}</div>
    </>
  );
}


export default function Survey({step, nextUrl}) {
  const {t, i18n} = useTranslation();
  const surveyList = getSurveyData(t('data', {ns: 'survey', returnObjects: true}), i18n.resolvedLanguage);

  const navigate = useNavigate();
  const topRef = React.useRef();
  const imageRef = React.useRef();
  const progressRef = React.useRef();
  const h1Ref = React.useRef();

  let hash = +window.location.hash.replace('#', '');

  if (!Number.isInteger(hash) || hash >= surveyList.length)
    hash = '';

  const [state, setState] = React.useState({
    index: (hash || 0),
    resultList: getItem('survey_results'),
    imageSize: {},
  });

  let [progress, setProgress] = React.useState(0);

  // const A5 = getExperiment('A5');
  const A5 = {value: 'A'};

  const sex =
    +state.resultList?.[
      surveyList.findIndex(({id}) => id === 'gender')
      ]?.[0] === 0 ? 'man' : 'woman';

  const
    s1 = surveyList.findIndex(({id}) => id === 'target1'),
    g1 =
      window.landing.includes('masculinity')
        ? surveyList[s1].list.findIndex(({id, title}) => id === 'Masculinity' || title === 'Masculinity')
        : +state.resultList?.[s1]?.[0] || 0,
    goal = surveyList[s1].list[g1] || surveyList[s1].list[0],
    goalId = goal ? (goal.id || goal.title).toLowerCase().replace(/ /g, '-') : ''

  const getSurveyByIndex = (index, survey) => {
    if (!survey)
      survey = {...surveyList[index]};

    if (survey?.switch) {
      const sIndex =
        survey.target
          ? surveyList.findIndex(item => item.id === survey.target)
          : index - 1;
      let rIndex;

      // if (surveyList[sIndex].previousOne) {
      //   rIndex =
      //     state.resultList[sIndex - 1]?.[
      //       state.resultList[sIndex]?.[0]
      //       ];
      // } else
        rIndex = // state.resultList[sIndex]?.[0];
          window.landing.includes('masculinity') && survey.target === 'target1' && survey.id !== 'target2'
            ? g1
            : state.resultList[sIndex]?.[0];

      if (survey.targetIndex) {
        rIndex = survey.switch.findIndex(item => item.targetIndex === rIndex);

        if (rIndex < 0)
          rIndex = 0;
      }

      const switchSurvey = () => {
        survey = survey.switch[rIndex];

        if (survey?.switch)
          survey = getSurveyByIndex(index, survey);
      };

      switchSurvey();
    } else if (Array.isArray(survey?.target)) {
      if (i18n.resolvedLanguage === 'en' && survey.id === 'graph') {
        // A9 = getExperiment('A9');
        //
        // if (A9.value === 'B') {
        //   const experiment = survey['experiment-A9'].B;
        //   delete survey.rive;
        //   delete survey.note;
        //   delete survey.subheader;
        //   survey.image = experiment.image;
        //   survey.name = experiment.name[g1];
        //   survey.underSubheader = experiment.underSubheader;
        //   survey.items = experiment.items;
        // }
      }

      for (const [i, target] of survey.target.entries()) {
        const si = surveyList.findIndex(item => item.id === target);
        const sur = getSurveyByIndex(si);
        const res =
          window.landing.includes('masculinity') && target === 'target1'
            ? [g1]
            : state.resultList[si];

        if (res && sur && survey?.subheader) {
          let obj = sur.list[res[0]]?.subtitle || sur.list[res[0]]?.title;

          if (i && obj)
            obj = obj.toLowerCase();

          survey.subheader = survey.subheader.replace(`{obj${i + 1}}`, obj);
        }
      }

      let date = new Date();
      date.setMonth(date.getMonth() + 3);

      if (survey.random) {
        date.setDate(
          date.getDate() - 5 - Math.floor(Math.random() * 11)
        );
      }

      const day = date.getDate();
      // const month = date.toLocaleString("default", { month: "short" });
      // const year = date.getFullYear();

      const nthNumber = (number) => {
        return number > 0 && i18n.resolvedLanguage === 'en'
          ? ["th", "st", "nd", "rd"][
            (number > 3 && number < 21) || number % 10 > 3 ? 0 : number % 10
            ]
          : "";
      };

      // const dateString = date.toLocaleDateString('en-us', {weekday: "long", year: "numeric", month: "short", day: "numeric"});
      // const dateString = date.toLocaleDateString(i18n.resolvedLanguage, {month: "short", day: "numeric"}) + nthNumber(day);
      // const dateLongString = date.toLocaleDateString(i18n.resolvedLanguage, {month: "long", day: "numeric"});
      // const dateShortString = date.toLocaleDateString(i18n.resolvedLanguage, {month: "long"});

      // const formatter = new Intl.DateTimeFormat('en-US', { dateStyle: 'short' });
      // const formatter = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: '2-digit', year: 'numeric' });
      // const dateString = formatter.format(date);

      // // const dateString = `${month} ${day}${nthNumber(day)}, ${year}`;
      // const dateString = `${month} ${day}${nthNumber(day)}`;

      // const s1 = surveyList.findIndex(({id}) => id === 'target1');
      //
      // if (surveyList[s1].list[+state.resultList?.[s1]?.[0] || 0]?.shortDate)
      //   survey.subheader = survey.subheader.replace('{date}', '{date-short}');

      const insertDate = (string) => {
        const map = {
          '{date}': date.toLocaleDateString(i18n.resolvedLanguage, {month: "short", day: "numeric"}) + nthNumber(day),
          '{date-long}': date.toLocaleDateString(i18n.resolvedLanguage, {month: "long", day: "numeric"}),
          '{date-short}': date.toLocaleDateString(i18n.resolvedLanguage, {month: "long"}),
        };

        if (MonthEnum[i18n.resolvedLanguage])
          map['{date-short-1}'] = MonthEnum[i18n.resolvedLanguage][date.getMonth()];
        else
          map['{date-short-1}'] = map['{date-long}'].split(' ')[1];

        return string.replace(/\{date(-long|-short|-short-1)?\}/g, function(m) { return map[m]; });
      };

      if (survey.subheader)
        survey.subheader = insertDate(survey.subheader);

      if (survey.note)
        survey.note = insertDate(survey.note);
    }

    if (Array.isArray(survey?.name))
      survey.name = survey.name[g1] || survey.name[0];

    if (survey?.type === SurveyEnum.boolean && !survey.list)
      survey.list = [
        { "icon": <IconNo/>, "title": t("no") },
        { "icon": <IconYes/>, "title": t("yes") }
      ];

    // if (
    //   A5.value === 'B' &&
    //   survey?.['title-A5']
    // )
    //   survey.title = survey['title-A5'];

    if (survey && surveyList[index]?.type === SurveyEnum.pic) {
      // const A29 = getExperiment('A29');
      // survey.image = 'face/' + (A29.value === 'B' ? 'new/' : '') + (survey.id || survey.title).replace(/ /g, '-') + '.png';

      // let A33
      //
      // if (survey.A33 && goalId !== 'singing') {
      //   A33 = getExperiment('A33')
      //
      //   if (A33.value === 'B') {
      //     survey.id = survey.A33.id
      //     survey.title = survey.A33.title
      //   }
      // }
      //
      // survey.image = `i18/${A33?.value === 'B' ? i18n.resolvedLanguage : 'en'}/img/survey/face/` + (survey.id || survey.title).toLowerCase().replace(/ /g, '_').replace(/\./g, '') + '.png';

      let isLocale = true

      if (goalId === 'singing' || ['ru', 'uk', 'es'].includes(i18n.resolvedLanguage))
        isLocale = false
      // else if (survey.A33) { // FIXME: Для es проверим новые паки селеб в будущем
      //   survey.id = survey.A33.id
      //   survey.title = survey.A33.title
      // }

      survey.image = `i18/${isLocale ? i18n.resolvedLanguage : 'en'}/img/survey/face/` + (survey.id || survey.title).toLowerCase().replace(/ /g, '_').replace(/\./g, '') + '.png';
    } else if (survey?.image)
      survey.image = 'img/survey/' + survey.image.replace('{sex}', sex)

    // if (
    //   A5.value === 'B' && !survey?.imageRoot &&
    //   survey?.image && !survey.image.includes('nice/')
    // ) {
    //   if (
    //     goal.sex && survey.image.match(/(extrovert|introvert|talker|listener|confident|journey|mirror|impressed)\./) ||
    //     goalId === 'singing' && survey.image.match(/ambivert\./) ||
    //     goalId === 'self-confidence' && survey.image.match(/boost\./)
    //   )
    //     survey.image = survey.image.replace('.', `_${sex}.`)
    //
    //   survey.image = `nice/${!survey.imageCommon && surveyList[index]?.type !== SurveyEnum.pic ? goalId + '/' : ''}` + survey.image;
    // }

    if (survey?.rive?.image && !survey.rive.image.includes('/static/media/'))
      survey.rive.image = require('./assets/img/survey/' + survey.rive.image);

    if (survey?.rive?.value === 'target1')
      survey.rive.value = g1 + 1;
    else if (survey?.rive?.random)
      survey.rive.value = Math.floor(Math.random() * survey.rive.random) + 1;
    // else if (survey?.rive?.inputName === 'localisation_id')
    //   survey.rive.value = LanguageEnum[i18n.resolvedLanguage];

    return survey;
  };

  let
    survey = getSurveyByIndex(state.index),
    surveyNext = getSurveyByIndex(state.index + 1);

  let isPic = false;

  if (surveyList[state.index]?.type === SurveyEnum.pic)
    isPic = true;

  // preload images
  let surList;

  if (surveyNext)
    surList = [surveyNext];
  else
    surList = surveyList[state.index + 1]?.switch || [];

  for (const sur of surList) {
    if (
      sur?.image && (!sur.rive || A5.value === 'B') &&
      (!window.imageList || !window.imageList.includes(sur.image))
    ) {
      const img = new Image();
      img.src = require('./assets/' + sur.image);

      if (!window.imageList)
        window.imageList = [];

      window.imageList.push(sur.image);
    }
  }


  React.useLayoutEffect(() => {
    if (state.index && !state.resultList[state.index - 1]) {
      state.index = 0;
      setState({...state});
    }
  }, []);

  React.useLayoutEffect(() => {
    if (survey) {
      if (isDeleted(survey)) {
        state.resultList[state.index] = [];
        state.index++;
      } else
        setStep(
          {
            step: state.index + step,
            page: (survey.name || survey.description).replace(/<br>/g, ' ') + (surveyList[state.index]?.type === SurveyEnum.pic ? ' - ' + survey.title : '')
          },
          false
        );

      // if (survey.experiment)
      //   setExperiment(survey.experiment);

      if (state.index !== hash) {
        if (state.index)
          navigate('#' + state.index);
        else
          navigate('');
      }
    }

    if (state.index === 17)
      trackContent(17);

    window.timerProgress && clearInterval(window.timerProgress);
  }, [state.index]);
  // }, [state.index, survey]);

  React.useLayoutEffect(() => {
    if (
      hash !== state.index &&
      // Number.isInteger(hash) && hash < surveyList.length &&
      state.index < surveyList.length - 1 &&
      (state.resultList[hash] || state.resultList[hash - 1])
    ) {
      state.index = hash;
      backProcess();
      setState({...state});
    }
  }, [hash]);

  React.useEffect(() => {
    const style = document.createElement('style');

    style.innerHTML =
      `.map {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        background-image: url("${require('./assets/img/survey/map-only.png')}");
      }
      .map canvas { transform: translate3d(0,-8px,0); }`;

    document.body.append(style);

    const img = new Image();
    img.src = require('./assets/img/survey/map-only.png');
  }, []);

  React.useEffect(() => {
    if (progress) {
      setProgress(0);
      // return;
    }

    if (survey && survey.type === SurveyEnum.progress) {
      let
        interval = 80, time = interval,
        pauses = 2 + Math.floor(Math.random() * 3),
        pause = 0;

      console.log('pauses', pauses)

      // const animation = (src) => {
      //   imageRef.current?.setAttribute('src', src);
      //
      //   h1Ref.current?.classList.add('fade-in');
      //   imageRef.current?.classList.add('fade-in');
      //
      //   setTimeout(() => {
      //     h1Ref.current?.classList.remove('fade-in');
      //     imageRef.current?.classList.remove('fade-in');
      //   }, 1000);
      // };

      const startTimer = () => {
        window.timerProgress = setInterval(() => {
          setProgress(++progress);

          if (progress > 100) {
            clearInterval(window.timerProgress);
            handleNext();
          } else {
            // if (A12?.value === 'B') {
            //   switch (progress) {
            //     case 33:
            //       h1Ref.current.innerHTML = 'Fine-tuning AI to match<br>your voice';
            //       animation(require('./assets/img/survey/processing-2.png'));
            //       break;
            //
            //     case 66:
            //       h1Ref.current.innerHTML = 'Preparing questions for<br>AI optimization';
            //       animation(require('./assets/img/survey/processing.png'));
            //       break;
            //   }
            // }

            if (!pause && pauses > 0) {
              pause = progress + Math.floor(Math.random() * Math.min(99 - progress, 30)) - 1;
              console.log('pause', pause)
              pauses--;
            }

            if (pause && progress >= pause) {
              console.log('paused')
              clearInterval(window.timerProgress);
              pause = 0;
              // time = interval * (Math.floor(Math.random() * 2) ? 0.5 : 2);
              setTimeout(startTimer, 250 + Math.floor(Math.random() * 750));
            }
          }
        }, time);
      };
      startTimer();

      return () => {
        clearInterval(window.timerProgress);
      };
    }
  }, [state.index]);
  // }, [survey]);

  React.useLayoutEffect(() => {
    if (survey?.image) {
      if (isPic) {
        state.imageSize = {
          width: 160,
          height: 160,
        };
      } else if (topRef.current) {
        const style = window.getComputedStyle(topRef.current, null)
        const width = topRef.current.clientWidth - parseFloat(style.paddingLeft) - parseFloat(style.paddingRight)

        state.imageSize = {
          width,
          height: width / (7 / 4),
          style: {aspectRatio: 7 / 4},
        };
      }

      setState({...state});
    }
  }, [state.index, topRef.current]);

  const isNextEnable = () => {
    return [SurveyEnum.info, SurveyEnum.infoReverse].includes(survey.type) || state.resultList[state.index]?.length > 0 || survey.optional;
  };

  const isNext = () => {
    return (
      survey.multiple ||
      survey.type && [SurveyEnum.info, SurveyEnum.infoReverse, SurveyEnum.pic, SurveyEnum.startLine].includes(survey.type)
    );
  };

  function handleNext(force) {
    if ([SurveyEnum.info, SurveyEnum.infoReverse, SurveyEnum.progress].includes(survey.type))
      state.resultList[state.index] = [true];
    else if (!state.resultList[state.index])
      state.resultList[state.index] = [];

    save();

    state.index++;

    if (state.index >= surveyList.length) {
      navigate(nextUrl);
      return;
    }
    // else if (survey.rive && surveyNext?.rive?.inputName) {
    //   //FIXME: fix multiple rive load
    //   console.warn('fixme', state.index)
    //   window.location.href = '/survey#' + state.index;
    //   window.location.reload();
    //   return;
    // }

    // if (surveyList?.[state.index]?.previousOne) {
    //   surveyList[state.index].list = [];
    //
    //   const sorted = state.resultList[state.index - 1].sort();
    //
    //   for (const i of sorted)
    //     surveyList[state.index].list.push(
    //       surveyList[state.index - 1].list[i]
    //     );
    //
    //   if (state.resultList[state.index - 1].length === 1) {
    //     state.resultList[state.index] = [0]; // state.resultList[state.index - 1];
    //     state.index++;
    //   }
    // }

    if (!force)
      setState({...state});
  }

  const handleFillResults = () => {
    while (state.index < surveyList.length) {
      if (!state.resultList[state.index]?.length)
        state.resultList[state.index] = [0]

      handleNext(true)
      survey = getSurveyByIndex(state.index)
    }

    navigate(nextUrl)
  }

  const save = () => {
    window.localStorage.setItem(
      'survey_results',
      JSON.stringify(state.resultList)
    );
  };

  function handleBack() {
    state.index--;
    backProcess();

    if (state.index < 0)
      navigate('/');
    else
      setState({...state});
  }

  const isDeleted = (sur) => {
    if (sur?.deleted)
      return true;

    if (sur?.target && sur?.exclude) {
      const si = surveyList.findIndex(item => item.id === sur.target);
      const res = state.resultList[si];

      if (sur.exclude.includes(res[0]))
        return true;
    }

    return false;
  };

  const backProcess = () => {
    if ([SurveyEnum.info, SurveyEnum.infoReverse].includes(surveyList?.[state.index]?.type))
      state.index--;

    // if (surveyList?.[state.index]?.previousOne && state.resultList[state.index].length === 1)
    //   state.index--;

    if (isDeleted(surveyList?.[state.index]))
      state.index--;

    // state.resultList = state.resultList.splice(0, state.index);
    save();

    window.timerProgress && clearInterval(window.timerProgress);
  };

  function handleChange(checkedList) {
    // console.log('handleChange', checkedList)

    if (state.resultList[state.index]) {
      const every = checkedList.every(r => state.resultList[state.index].includes(r))

      if (!every || state.resultList[state.index].length !== checkedList.length) {
        for (let i = state.index + 1; i < surveyList.length; i++) {
          const sur = surveyList[i];

          // if (state.resultList[i] && (sur.switch || sur.previousOne)) {
          if (state.resultList[i] && sur.switch) {
            const sIndex =
              sur.target
                ? surveyList.findIndex(item => item.id === sur.target)
                : i - 1;

            if (sIndex === state.index)
              state.resultList[i] = [];
          }
        }
      }
    }

    state.resultList[state.index] = checkedList;
    setState({...state});
    save();

    const
      item = survey.list?.[checkedList[0]],
      itemTitle = item?.id || item?.title;

    switch (surveyList[state.index].id) {
      case 'gender':
        mixpanel.people.set({gender: itemTitle});
        break;

      case 'target1':
        // mixpanel.track(survey.id, {value: itemTitle});
        // mixpanel.track_pageview({page: survey.id});

        setStep(
          {
            step: state.index + step,
            page: survey.name.replace(/<br>/g, ' '),
            [survey.id]: itemTitle
          },
          false
        );

        mixpanel.register({user_goal1: itemTitle});
        break;

      case 'target2':
        const user_goal2 = [];

        for (const j of checkedList) {
          let title = survey.list?.[j]?.id || survey.list?.[j]?.title;

          if (title)
            title = title.replace(/<br>/g, ' ');

          user_goal2.push(title);
        }

        mixpanel.register({user_goal2});
        break;
    }

    // if (!survey.type || survey.type === SurveyEnum.boolean) {
    if (!survey.multiple || survey.type === SurveyEnum.boolean) {
      upTimer(() => {
        handleNext();
      });
    }
  }

  if (!survey) {
    console.error('survey is empty', state.index, surveyList, state.resultList);
    return;
  }

  return (
    <>
    {
      // i18n.resolvedLanguage === 'en' &&
      // <TopApp/>
    }
    <div className={'wrap fixed' + (isNext() ? ' md' : '')}>
      <Top tref={topRef} type={survey.type}>
        {
          process.env.REACT_APP_DEBUG &&
          <Button variant={'gold'} size={'sm'} onClick={handleFillResults} style={{position: 'fixed', top: 5, right: 5, width: 'auto'}}>fill</Button>
        }
        <div className={'row'} style={state.index > 0 ? {gap: '1.55rem'} : {height: '40px', justifyContent: 'center'}}>
          {
            state.index > 0 &&
            <Back onClick={handleBack}/>
          }
          <Logo/>
        </div>

        <Steps index={state.index}/>

        {
          survey.name && [SurveyEnum.infoReverse, SurveyEnum.progress].includes(survey.type) &&
          <h1 ref={h1Ref} className={survey.name?.length > 50 || survey.underSubheader ? 'h3' : ''} dangerouslySetInnerHTML={{__html: survey.name}}/>
        }

        {
          isPic &&
          <h1 className={'h3 mb-4'} dangerouslySetInnerHTML={{__html: survey.name}}/>
        }

        {
          survey.subheader &&
          <h2 className={'h4 marked'} dangerouslySetInnerHTML={{__html: survey.subheader}}/>
        }

        {
          survey.image && (!survey.rive || A5.value === 'B') &&
          <div className={'image'}>
            <img ref={imageRef} src={require('./assets/' + survey.image)} alt="" className={'d-block'}
                 {...state.imageSize} onLoad={() => imageRef.current.classList.add('mw-100')}/>
            {
                // survey.underSubheader &&
                // <strong className={'small'} dangerouslySetInnerHTML={{__html: survey.underSubheader}}/>
            }
          </div>
        }

        {
          //FIXME: multiple input rives
          survey.rive && (!survey.image || A5.value === 'A') &&
          !surveyNext?.rive?.inputName &&
          <RiveLoad {...survey.rive}/>
        }
        {
          survey.rive && (!survey.image || A5.value === 'A') &&
          surveyNext?.rive?.inputName &&
          <RiveLoad {...survey.rive}/>
        }

        {
          survey.underSubheader &&
          <strong className={'small'} style={{marginTop: -10}} dangerouslySetInnerHTML={{__html: survey.underSubheader}}/>
        }

        {
          ![SurveyEnum.infoReverse, SurveyEnum.progress].includes(survey.type) && (isPic && survey.title || survey.name) &&
          <h1 className={'h3'} dangerouslySetInnerHTML={{__html: isPic ? survey.title : survey.name}}/>
        }

        {
          survey.items && <div />
        }
        {
          survey.items?.map((item, ind) =>
            <div key={ind} className={'row'}>
              <img src={require('./assets/img/survey/' + item.image)} alt="" width={item.width} height={item.height}/>
              <p className={'notice text-start bold-light'} style={{fontSize: 8, lineHeight: '10px'}} dangerouslySetInnerHTML={{__html: item.text}}/>
            </div>
          )
        }

        {
            survey.description &&
            <p className={survey.type !== SurveyEnum.infoReverse ? 'notice small' : ''}
               dangerouslySetInnerHTML={{__html: survey.description}}/>
        }

        {
          survey.note &&
          <p className={'notice xs'} dangerouslySetInnerHTML={{__html: survey.note}}/>
        }

        {
          survey.list &&
          <ChoiceList index={state.index} survey={survey} checkedList={state.resultList[state.index]} onChange={handleChange}/>
        }

        {
          survey.type === SurveyEnum.progress &&
          <div className={'progress lg animate'}>
            <em className={'value'}>{progress}%</em>
            <div ref={progressRef} style={{width: progress + '%'}}></div>
          </div>
        }
      </Top>

      <Bottom>
        {
          isNext() &&
          <Button disabled={!isNextEnable()} onClick={() => handleNext()}>{survey.btn || t('continue')}</Button>
        }
      </Bottom>

      {
        surveyNext?.rive && (!surveyNext.image || A5.value === 'A') &&
        <RivePreload src={surveyNext.rive.src} locale={surveyNext.rive.locale}/>
      }
    </div>
    </>
  );
}
